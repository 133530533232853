import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CompanyModel } from '../_models/user-model';
import { UserModel } from '../_models/user-model';
import { EMAIL, COMMONCODE } from '../_helpers/constants';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  userData = new BehaviorSubject<UserModel>({});
  currentUserData = this.userData.asObservable();
  baseUrl = 'api/';

  constructor(private http: HttpClient) {}

  getAccountInfo(): Observable<UserModel> {
    const email = sessionStorage.getItem(EMAIL);
    const commonCode = sessionStorage.getItem(COMMONCODE);
    const apiUrl =
      this.baseUrl + `retrieveCustomerAndCompanyData/${commonCode}/${email}`;
    return this.http.get<UserModel>(apiUrl);
  }

  getUserData() {
    // if userData not present, get it and setUserData
    if (!this.userData.value.user) {
      this.getAccountInfo().subscribe((res) => {
        this.setUserData(res);
      });
    }
    return this.currentUserData;
  }

  setUserData(userData: UserModel) {
    this.userData.next(userData);
  }

  getCompaniesList(): Observable<any> {
    const mail = sessionStorage.getItem(EMAIL);
    return this.http.get<CompanyModel>(
      this.baseUrl + 'retrieveUserCompanyData/' + mail
    );
  }

  getSapOptions(): Observable<any> {
    const commonCode = sessionStorage.getItem(COMMONCODE);
    return this.http.get<any>(this.baseUrl + `sapOptions/${commonCode}`);
  }

  getPaymentMethods(sapCode: string): Observable<any> {
    const commonCode = sessionStorage.getItem(COMMONCODE);
    return this.http.get<any>(
      this.baseUrl + `paymentOptions/${commonCode}/${sapCode}`
    );
  }
}
