export const COMPANYNAME = 'companyName';
export const COMPANYTYPE = 'companyType';
export const COMPANYCITY = 'companyCity';
export const EMAIL = 'email';
export const USERNAME = 'userName';
export const COMMONCODE = 'commonCode';

// export const requestTypeListConst: string[] = [
//     'Account information change request',
//     'Copy of invoice request',
//     'Info on payments',
//     'Info on extensions',
//     'Technical issue',
//     'Other',
//   ];

export const requestTypeListConst: string[] = [
  'contactUs.op1',
  'contactUs.op2',
  'contactUs.op3',
  'contactUs.op4',
  'contactUs.op5',
  'contactUs.op6',
];
